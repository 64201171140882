import React, { useEffect } from 'react'

export default function TerminalOverlay() {

    const handleUnload = e => {
        // i don't think this value is actually used by browsers, but if it's set it... alerts?
        e.returnValue = "WARNING: A transaction is currently being processed."
    }

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload)

        // when the component unmounts, this should remove the event listener
        return () => {
            window.removeEventListener('beforeunload', handleUnload)
        }
    }, [])

    return (
        <div className="overlay-container">
            <div className='terminal-overlay'></div>

            <div className='terminal-overlay-content'>
                Processing Transaction
            </div>
        </div>
    )
}
